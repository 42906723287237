<template>
    <div>
        <vx-card>
            <div slot="no-body" class="tabs-container px-6 pt-6">
                <div class="vx-row mb-5">
                    <div class="vx-col md:w-1/3 flex items-center">
                        <TitleScreen></TitleScreen>
                    </div>
                </div>
                <!-- Content Row -->
                <div class="vx-row ">
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <label class="vs-input--label required">{{$t('menuOrganization.branchName')}}</label>
                        <vs-input class="w-full"
                                  v-model="branchInfo.organizationBranchName"
                                  :disabled="isUpdate"
                                  v-validate="'required'" name="organizationBranchName"/>
                        <span class="text-danger text-sm">{{ errors.first('organizationBranchName') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.email')"
                                  v-model="branchInfo.email"
                                  :disabled="isUpdate"
                                  v-validate="'required|email|min:3'" name="email"/>
                        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                    </div>
                    <div class="vx-col md:w-1/3 w-full mt-2">
                        <vs-input class="w-full" :label="$t('menuOrganization.phoneNumber')"
                                  v-model="branchInfo.phoneNumber"
                                  v-validate="'numeric|min:10|max:11'" name="phoneNumber"/>
                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                    </div>
                </div>

                <!-- Maps -->
                <div class="vx-row mt-2 mb-5">
                    <div class="vx-col md:w-1/3 w-full">
                        <label class="vs-input--label">{{ $t('menuOrganization.branchType') }}</label>
                        <v-select class="" v-model="branchTypeModel" :clearable="false" :options="branchTypeOptions"
                                  data-vv-validate-on="blur" v-validate="'required'" name="organizationType">
                            <span slot="no-options"> {{ $t('menuOrganization.noOption') }}</span>
                        </v-select>
                    </div>
                    <div class="vx-col md:w-2/3 w-full">
                        <label data-v-8f168c40="" class="vs-input--label required">{{ $t('menuOrganization.locationMap') }}</label>
                        <vue-google-autocomplete data-v-8f168c40="" type="text" id="map"
                                                 :placeholder="$t('menuOrganization.enterAddress')" value=""
                                                 class="vs-inputx vs-input--input normal hasValue pac-target-input"
                                                 v-model="branchInfo.fullAddress"
                                                 ref="address"
                                                 v-validate="'required'"
                                                 name="enterAddress"
                                                 v-on:placechanged="getAddressData"
                                                 v-on:error="handleError">
                        </vue-google-autocomplete>
                        <span class="text-danger text-sm">{{ errors.first('enterAddress') }}</span>
                    </div>
                </div>
                <vx-card>
                    <GmapMap
                        :center="center"
                        :zoom="16"
                        map-type-id="terrain"
                        style="width: 100%; height: 50rem">
                        <GmapMarker
                            :key="index"
                            v-for="(marker, index) in markers"
                            :position="marker.position"
                            :clickable="true"
                            country="vi"
                            @click="center=marker.position"
                        />
                    </GmapMap>
                </vx-card>
                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full mb-5">
                        <div class="mt-8 flex flex-wrap items-center justify-end">
                            <vs-button class="ml-auto mt-2" @click="saveChanges" :disabled="!validateForm">{{
                                    `${isUpdate ?
                                        $t('button.update') : $t('button.create')}`
                                }}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="branchList">
                                {{ $t('button.back') }}
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>
<style>
#vs2__listbox li {
    position: absolute;
    z-index: 9999;
}
</style>
<script>
import vSelect from 'vue-select';
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import GoogleAutocomplete from "./components/GoogleAutocomplete";
import province from '@/assets/utils/province.js';
import TitleScreen from "../../../layouts/main/TitleScreen";

export default {
    components: {
        vSelect,
        VueGoogleAutocomplete,
        GoogleAutocomplete,
        TitleScreen
    },

    data() {
        return {
            addressMap: null,
            address: '',
            fullAddress: '',
            markerLocal: null,
            addressString: '',
            places: [],
            activeTab: 0,
            branchInfo: {},
            input: [],
            isUpdate: false,
            cityOptions: province.getProvince(),
            districtOptions: province.getDistrict('01'),
            subDistrictOptions: province.getSubDistrict('01', '001'),
            branchTypeOptions: [
                {label: this.$t('menuOrganization.null'), value: 0},
                {label: this.$t('menuOrganization.principalOffice'), value: 1},
                {label: this.$t('menuOrganization.branch'), value: 2},
            ],
            mapLocal: '',
            options: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
            },
            center: {lat: 21.032041, lng: 105.782851},
            markers: [],
            currentPlace: null,
        }
    },
    created() {
        if (this.$route.query.id) {
            this.isUpdate = true;
            this.$crm.post('/organization-branch/all').then((response) => {
                const dataResult = response.data.find(x => x.id == this.$route.query.id);
                this.branchInfo = dataResult;
                const position = {
                    longitude: this.branchInfo.longitude,
                    latitude: this.branchInfo.latitude
                }
                setTimeout(() => {
                    this.addMarker(position)
                }, 0)
                this.markerLocal = position;
                this.$vs.loading.close();
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        }
        let branchInfo = Object.assign({}, this.$store.state.branchUpdateInfo || null),
            userInfo = JSON.parse(localStorage.getItem('userInfo') || {});

        this.$store.commit('BRANCH_UPDATE_INFO', null);

        if (Object.keys(branchInfo).length === 0 && branchInfo.constructor === Object) {
            return this.branchInfo = {
                organizationBranchName: null,
                fullAddress: null,
                organizationBranchType: 0,
                organizationId: userInfo.organization.id,
                phoneNumber: null,
                city: "01",
                district: "001",
                houseNumber: null,
                street: "00001",
                latitude: 0,
                longitude: 0,
            }
        }
        this.isUpdate = true;
        this.branchInfo = branchInfo;
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        },
        branchTypeModel: {
            get() {
                return {
                    label: this.getLabelString(this.branchTypeOptions.find(x => x.value === this.branchInfo.organizationBranchType)),
                    value: this.branchInfo.organizationBranchType
                }
            },
            set(obj) {
                this.branchInfo.organizationBranchType = obj.value
            }
        },
    },

    watch: {
        markerLocal(value) {
            const marker = {
                lat: value.latitude,
                lng: value.longitude
            };
            this.addMarker(marker);
        },
    },

    methods: {
        getPosition(val) {
            this.branchInfo.latitude = val[0].position.lat
            this.branchInfo.longitude = val[0].position.lng
        },
        getLabelString(object) {
            return (object && object.label) || this.$t('menuOrganization.null');
        },
        getAddressData(addressData, placeResultData, id) {
            this.$crm.get(`/organization-branch/place/auto-complete?input=${this.input}`)
                .then(() => {
                    const marker = {
                        lat: this.currentPlace.geometry.location.lat(),
                        lng: this.currentPlace.geometry.location.lng()
                    };
                    this.geometry = marker
                    this.addMarker(marker);
                    this.$vs.loading.close();
                })
                .catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            this.address = addressData;
            this.branchInfo.fullAddress = placeResultData.formatted_address;
            this.branchInfo.latitude = addressData.latitude;
            this.branchInfo.longitude = addressData.longitude;
            this.addMarker(addressData);
        },
        handleError(error) {
            alert(error);
        },
        addMarker(marker) {
            const markerShow = {
                lat: marker.latitude,
                lng: marker.longitude
            };
            this.markers.push({position: markerShow});
            this.places.push(this.currentPlace);
            this.center = markerShow;
            this.currentPlace = null;
            console.log('marker', this.markers)
            this.$emit("mapLocation", this.markers);
        },
        geolocate: function () {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
            });
        },
        async saveChanges(evt) {
            evt.preventDefault();
            try {
                if (!this.validateForm) return;
                const result = await this.$validator.validateAll();
                if (!result) return;
                let url = this.isUpdate ? `/organization-branch/update/${this.branchInfo.id}` : '/organization-branch/create';
                this.$vs.loading();
                this.$crm.post(url, this.branchInfo).then(() => {
                    this.$vs.notify({
                        color: 'success',
                        text: `${this.isUpdate ? this.$t('button.update') : this.$t('button.create')} ${this.$t('warningMenuOrganization.textBranchSuccess')}`,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                    });
                    this.$vs.loading.close();
                    this.$router.push('/user/organization-branch').catch(() => {
                    })
                }).catch((err) => {
                    this.$vs.loading.close();
                    return this.$vs.notify({
                        text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                });
            } catch (ex) {
                console.log(ex);
            }
        },
        branchList() {
            this.$router.push('/user/organization-branch').catch(() => {
            })
        }
    },
    mounted() {
        this.$eventBus.$on("address_location", (event) => {
            this.branchInfo.fullAddress = event
            console.log('map', event)
        });
        this.geolocate();
    }
}
</script>

<style scoped>
.required:after {
    content:" *";
    color: red;
}
</style>
